#loading-spinner {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%s);
}
#loading-spinner-message {
    text-align: center;
    position:fixed;
    top: 55%;
    left: 50%;
}